import React from "react";
import Test from "./Test";
import Footer from "../../footer";

import './Portal.css'
import Header from "../../header";




function Portal() {
  return (
    <>
    <Header/>

    <Test/>
    <Footer/>
    </>
  );
}

export default Portal;
